"use client"

import { useSearchParams } from "next/navigation"
import Link, { type LinkProps } from "next/link"
import type { ReactNode } from "react"

const paramsToPreserve = ["utm_source", "utm_medium"]

interface Props extends LinkProps {
  children: ReactNode
  target?: string
  className?: string // Optional className
}
const LocalLink = ({ href, target, className, children, ...props }: Props) => {
  const searchParams = useSearchParams()

  // Get current query parameters
  const filteredParams = Array.from(searchParams.entries())
    .filter(([key]) => paramsToPreserve.includes(key))
    .reduce((acc, [key, value]) => {
      acc.append(key, value)
      return acc
    }, new URLSearchParams())
  const queryString = filteredParams.toString()

  const hrefWithParams =
    queryString && typeof href === "string"
      ? `${href}${href.includes("?") ? "&" : "?"}${queryString}`
      : href

  return (
    <Link
      href={hrefWithParams}
      target={target}
      className={className}
      {...props}
    >
      {children}
    </Link>
  )
}

export default LocalLink
