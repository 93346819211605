"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Icon } from "@/components/ui/icon"
import sendMail, { sendToSalesForce } from "@/actions/mailActions"
import { cn } from "@/lib/utils"
import { useRouter, useSearchParams } from "next/navigation"
import { affiliations, type Affiliation } from "@/affiliations-config"
import { useRef, useState } from "react"
import { sendOtp } from "@/actions/otpAction"
import OtpValidationDialog from "@/forms/otp-validation-form"

const FormSchema = z.object({
  name: z.string(),
  mobile: z.string(),
  lookingFor: z.string(),
})

export function HeroForm({
  className,
  affiliation,
  formId,
}: {
  className?: string
  affiliation: Affiliation
  formId?: string
}) {
  const params = useSearchParams()
  const { toast } = useToast()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownValue, setDropdownValue] = useState("")
  const [formData, setFormData] = useState<z.infer<typeof FormSchema> | null>(
    null
  )
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      mobile: "",
      lookingFor: "",
    },
  })

  async function processLead(formData: z.infer<typeof FormSchema>) {
    if (dropdownValue === "") {
      toast({
        variant: "destructive",
        description:
          "Please tell us what you are looking for by selecting a value from the dropdown",
      })
      return
    }
    setIsLoading(true)
    const emailBody = `
      <p>===============================================</p>
      <h1>${formData.name} needs help with ${formData.lookingFor}</h1>
      <h3>From: ${formData.name}</h3>
      <h3>Contact Number: ${formData.mobile}</h3>
      <h3>Looking For: ${formData.lookingFor}</h3>
      <h3>UTM source: ${params.get("utm_source")}</h3>
      <h3>UTM medium: ${params.get("utm_medium")}</h3>
      <p>===============================================</p>
    `
    try {
      const mailSubject = `${formData.lookingFor} - ${affiliation === affiliations.defaultHospital ? "Group Hospitals" : affiliation}`

      const success = await sendMail(emailBody, mailSubject)
      if (success) {
        await sendToSalesForce({
          last_name: formData.name,
          phone: formData.mobile,
          subject: mailSubject,
          pageType: "Hero",
          pageName: formData.lookingFor,
          source: params.get("utm_source") || undefined,
          medium: params.get("utm_medium") || undefined,
        })
        router.push(
          `/${affiliation}/thankyou?name=${encodeURIComponent(formData.name)}`
        )
      } else {
        toast({
          variant: "destructive",
          description:
            "Something went wrong while processing your information. Please try again later",
        })
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      toast({
        variant: "destructive",
        description: "Something went wrong. Please try again later",
      })
      setIsLoading(false)
    }
  }

  async function verifySubmission({
    data,
  }: {
    data: z.infer<typeof FormSchema>
  }) {
    setFormData(data)
    setIsDialogOpen(true)
    sendOtp(data.mobile)
    setPhoneNumber(data.mobile)
  }

  return (
    <Form {...form}>
      <form
        id={formId || "form"}
        onSubmit={form.handleSubmit((data) => verifySubmission({ data }))}
        className={cn(
          "flex h-full w-full flex-col items-center justify-between gap-2 px-4 text-primary sm:flex-row",
          className
        )}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="w-full sm:basis-1/5">
              <FormControl>
                <Input
                  placeholder="Enter your name"
                  {...field}
                  required
                  type="text"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mobile"
          render={({ field }) => (
            <FormItem className="w-full sm:basis-1/5">
              <FormControl>
                <Input
                  type="tel"
                  placeholder="Enter your mobile no."
                  {...field}
                  required
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lookingFor"
          render={({ field }) => (
            <FormItem className="w-full sm:basis-2/5">
              <Select
                onValueChange={(value) => {
                  field.onChange(value)
                  setDropdownValue(value)
                }}
                defaultValue={field.value}
                required
              >
                <FormControl>
                  <SelectTrigger className="text-muted-foreground">
                    <SelectValue placeholder="I am looking for...." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent
                  className="text-muted-foreground"
                  ref={(ref) =>
                    ref?.addEventListener("touchend", (e) => {
                      e.preventDefault()
                    })
                  }
                >
                  <SelectItem value="Doctor Consultation">
                    Doctor Consultation
                  </SelectItem>
                  <SelectItem value="Health Checkup">Health Checkup</SelectItem>
                  <SelectItem value="General Enquiry">
                    General Enquiry
                  </SelectItem>
                  <SelectItem value="LifeSaver Training">
                    LifeSavER Training
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isDialogOpen && (
          <Button
            className="w-full bg-custom-10 text-base font-medium sm:w-fit"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Verifying..." : "Submit"}
            <Icon
              name="heroicons--chevron-right-solid"
              className="h-4 w-4 pt-0.5"
            />
          </Button>
        )}

        <OtpValidationDialog
          phoneNumber={phoneNumber}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSuccess={() => formData && processLead(formData)}
          onFailure={() => setIsDialogOpen(false)}
        />
      </form>
    </Form>
  )
}
